import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "../address/AppBar";
import {useTheme} from "@material-ui/core";
import {AppTheme} from "../..";
import { useTranslation } from "react-i18next";

// import RiskMapEditor from './RiskMapEditor';
import EventEditor from "./EventEditor";

const drawerWidth = 250;

// TODO: navigation in main body with url change

export default function RiskEditor() {
    const theme = useTheme<AppTheme>();
    const { t } = useTranslation()

    const style = {
      backgroundColor: theme.backgroundColor.primary,
      height: "2.5rem",
      display: "flex",
      "justifyContent": "center",
  };

    return <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
            title="Risk Editor"
            position="fixed"
            sx={{ zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        />
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: "border-box",
                },
            }}
        >
          <Box sx={{ paddingTop: 8 }}>
            <ul>
              <li style={style} className={`NavItemActive`}>
                <span className="NavItemLink">
                  <p className="NavItemLabel">{t("blockTitle.event")}</p>
                </span>
              </li>
            </ul>
          </Box>
          {/* <Box sx={{ paddingLeft: "12px", paddingRight: "12px", paddingTop: 10 }} >
            Map
          </Box> */}
        </Drawer>
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                paddingLeft: 3,
                paddingRight: 3,
                paddingTop: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <EventEditor/>
        </Box>
      </Box>
}