export const TRANSLATIONS_FR = {
  11100: "Tissu urbain continu (S.L. > 80%)",
  11210: "Tissu urbain discontinu dense (S.L. : 50% - 80%)",
  11220: "Tissu urbain discontinu de densité moyenne (S.L. : 30% - 50%)",
  11230: "Tissu urbain discontinu de faible densité (S.L. : 10% - 30%)",
  11240: "Tissu urbain discontinu de très faible densité (S.L. < 10%)",
  11300: "Structures isolées",
  12100: "Unités industrielles, commerciales, publiques, militaires et privées",
  12210: "Voies de transit rapide et terrains associés",
  12220: "Autres routes et terrains associés",
  12230: "Chemins de fer et terrains associés",
  12300: "Zones portuaires",
  12400: "Aéroports",
  13100: "Sites d'extraction minière et décharges",
  13300: "Chantiers de construction",
  13400: "Terrains sans utilisation actuelle",
  14100: "Espaces verts urbains",
  14200: "Installations sportives et de loisirs",
  21000: "Terres arables (cultures annuelles)",
  22000: "Cultures permanentes (vignobles, arbres fruitiers, oliveraies)",
  23000: "Pâturages",
  24000: "Schémas de culture complexes et mixtes",
  25000: "Vergers à la périphérie des zones urbaines",
  31000: "Forêts",
  32000: "Associations de végétation herbacée (prairies naturelles, landes...)",
  33000:
    "Espaces ouverts avec peu ou pas de végétation (plages, dunes, rochers nus, glaciers)",
  40000: "Zones humides",
  50000: "Plans d'eau",
  noInfo: "Pas de information",
  showListCatnat: "Afficher la liste complète",
  hideListCatnat: "Masquer la liste complète",
  showCompleteList: "Liste complète des CATNAT",
  showLatestListByCategory: "Liste des derniers CATNAT par catégories",
  address: "Adresse",
  startDate: "Date début arrêté",
  endDate: "Date fin arrêté",
  url: "Lien URL",
  noPeril: "Il n'y a pas d'arrêtés de péril proche de cette adresse !",
  noCatnat: "Il n'y a pas de catnat sur ce code postal",
  addPeril: "Ajouter/modifier un arrêté de péril",
  noSeveso: "Il n'y a pas de site seveso proche de cette adresse !",
  identify: "Identifiez-vous",
  connect: "Se connecter",
  send: "Envoyer",
  email: "Email",
  password: "Mot de passe",
  remember: "Se souvenir de moi",
  forgotPassword: "Mot de passe oublié ?",
  forgotPasswordTitle: "Mot de passe oublié",
  cancel: "Annuler",
  newPassword: "Nouveau mot de passe",
  confirmPassword: "Confirmer le mot de passe",
  signOutAllDevices: "Se déconnecter de tous les appareils",
  submit: "Valider",
  changePass: "Vous devez changer de mot de passe",
  passwordNotMatch: "Les mots de passe ne correspondent pas",
  sendMailSuccess:
    "Vous recevrez bientôt un email avec des instructions plus détaillées.",
  resetPasswordSuccess: "Votre mot de passe a été réinitialisé.",
  passwordRules:
    "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.",
  passwordPolicyViolation:
    "Le mot de passe ne respecte pas la politique de sécurité.",
  sessionExpired: "Votre session a expiré. Veuillez vous reconnecter.",

// partie .json des textes

  "blockTitle": {
    "general": "Informations générales",
    "activities": "Activités à l'adresse postale",
    "aerial": "Image aérienne",
    "street": "Images street view",
    "weather": "Météo à une date précise",
    "climateChange": "Changement climatique",
    "catNat": "Catastrophes naturelles",
    "mandates": "Arrêtés de périls",
    "seveso": "Ets ICPE / SEVESO",
    "event": "Points d'attention : PPRn, PPRi, etc",
  },

  "weatherLabel": {
    "thunder": "Orages",
    "winds": "Vents violents",
    "snow": "Neige",
    "heatWave": "Canicule",
    "simpleHeatWave": "Vague de chaleur",
    "coldWave": "Vague de froid",
    "heavyRain": "Fortes pluies",
    "ice": "Gel",
    "flood": "Crue",
    "pollution": "Pollution",
    "submersion": "Submersion"
  },

  "riskLabel": {
    "drought": "Sécheresse",
    "flood": "Crues",
    "pluvialFlood": "Inondations",
    "fire": "Incendies",
    "hailStorm": "Grêle",
    "wind": "Vents violents",
    "heavyRain": "Fortes pluies",
    "coastalErosion": "Érosion côtière",
    "submersion": "Submersion"
  },

  "legend": {
      "riskLabel": {
      "drought": "Sécheresse",
      "flood": "Inondations par Crues (montée lente eau fluviale)",
      "pluvialFlood": "Inondations",
      "fire": "Incendies",
      "hailStorm": "Grêle",
      "wind": "Vents violents",
      "heavyRain": "Risques liés à une accumulation de précipitations",
      "coastalErosion": "Érosion côtière",
      "submersion": "Submersion",
    },
  },

  "scaleLabel": {
    "disabled": "Hors zone",
    "noRisk": "Pas de risque",
    "low": "Risque faible",
    "moderate": "Risque modéré",
    "high": "Risque élevé",
    "veryHigh": "Risque très élevé"
  },

  "infoRisk": {
    "drought": {
      "disabled": "Le sol ne contient pas d'argile",
      "noRisk": "Il existe un faible risque de sécheresse et/ou de retrait-gonflement des sols argileux au cours des 10 prochaines années.",
      "low": "Il existe un risque faible de sécheresse et/ou de retrait-gonflement des sols argileux au cours des 10 prochaines années.",
      "moderate": "Il existe un risque modéré de sécheresse et/ou de retrait-gonflement des sols argileux au cours des 10 prochaines années. Cette zone est exposée à l'argile et sujette à la sécheresse.",
      "high": "Il existe un risque élevé de sécheresse et/ou de retrait-gonflement des sols argileux au cours des 10 prochaines années. Cette zone est sujette aux dommages liés à l'argile et le risque de sécheresse est élevé.",
      "veryHigh": "Il existe un risque très élevé de sécheresse et/ou de retrait-gonflement des sols argileux au cours des 10 prochaines années. Cette zone est très sujette aux dégâts liés à l'argile, et le risque de sécheresse est très important."
    },
    "flood": {
      "disabled": "Cette adresse n'est pas dans une zone identifiée à risque d'inondation.",
      "noRisk": "Le risque de crue est très faible pour cette zone.",
      "high": "Le risque de crue dans cette zone est élevé sur une période de retour de 10 ans.",
      "low": "Le risque de crue dans cette zone est faible sur une période de retour de 10 ans.",
      "moderate": "Le risque de crue dans cette zone est modéré sur une période de retour de 10 ans.",
      "veryHigh": "Le risque de crue dans cette zone est très élevé sur une période de retour de 10 ans.",
    },
    "pluvialFlood": {
      "disabled": "Cette adresse n'est pas dans une zone identifiée à risque d'inondation pluviale.",
      "noRisk": "Le risque d'inondation pluviale dans cette zone est très faible sur une période de retour de 10 ans. La hauteur estimée de l'eau en cas d'inondation est inférieure à 20 cm.",
      "low": "Le risque d'inondation pluviale dans cette zone est faible sur une période de retour de 10 ans. La hauteur estimée de l'eau en cas d'inondation est inférieure à 50 cm.",
      "moderate": "Le risque d'inondation pluviale dans cette zone est modéré sur une période de retour de 10 ans. La hauteur estimée de l'eau en cas d'inondation est comprise entre 50 cm et 75 cm.",
      "high": "Le risque d'inondation pluviale dans cette zone est élevé sur une période de retour de 10 ans. La hauteur estimée de l'eau en cas d'inondation est comprise entre 75 cm et 1,4 m.",
      "veryHigh": "Le risque d'inondation pluviale dans cette zone est très élevé sur une période de retour de 10 ans. La hauteur estimée de l'eau en cas d'inondation est supérieure à 1,4 m."
    },
    "fire": {
      "disabled": "Cette adresse n'est pas dans une zone identifiée à risque d'incendie.",
      "noRisk": "Les conditions météorologiques propices aux incendies au cours des 10 prochaines années seront rarement propices à l'allumage d'incendies de forêt.",
      "low": "Il y a un risque faible d'incendies de forêt. L'indice Fire Weather Index ne devrait pas être supérieur à 50 avant 2030.",
      "moderate": "Il y a un risque modéré d'incendies de forêt. L'indice Fire Weather Index devrait souvent atteindre 100 jusqu'en 2030.",
      "high": "Il y a un risque élevé d'incendies de forêt. On s'attend à ce que l'indice Fire Weather Index dépasse souvent 100 jusqu'en 2030.",
      "veryHigh": "Il y a un risque très élevé d'incendies de forêt. L'indice Fire Weather Index devrait souvent dépasser 140 jusqu'en 2030.",
      "diffLevel" : "Le risque incendie dans cette zone est ajusté car c'est un centre ville ou une zone avec une forte densité de bâtiments et routes",
    },
    "hailStorm": {
      "disabled": "Cette adresse n'est pas dans une zone identifiée à risque de grêle.",
      "noRisk": "Le risque de grêle est très faible depuis 2000 et aucun changement significatif de fréquence et de taille de grêle n'est attendu d'ici 2030.",
      "low": "Le risque de grêle est faible depuis 2000 avec peu et pas d'événements dommageables. Une légère augmentation de la fréquence de grêle est attendue d'ici 2030.",
      "moderate": "Le risque de grêle est modéré depuis 2000 avec de grands événements de grêle (> 3 cm). Une augmentation de la fréquence de grêle est attendue d'ici 2030.",
      "high": "Le risque de grêle est élevé depuis 2000 avec de nombreux grands événements de grêle (> 3 cm). Une augmentation significative de la fréquence de grêle est attendue d'ici 2030.",
      "veryHigh": "Le risque de grêle est très élevé depuis 2000 avec des événements fréquents de grêle importants (> 4 cm). Une augmentation significative de la fréquence de grêle est attendue d'ici 2030.",
    },
    "wind": {
      "disabled": "Cette adresse n'est pas dans une zone identifiée à risque de vent violent.",
      "low": "Le risque de vent violent est très faible depuis 2000, avec peu ou pas d'événements dommageables. Une légère augmentation de la fréquence des événements de vents extrêmes est attendue d’ici 2030.",
      "noRisk": "Le risque de vents violents est faible depuis 2000 et aucun changement significatif dans la fréquence des événements de vents extrêmes n’est attendu d’ici 2030.",
      "moderate": "Le risque de vent violent est modéré depuis 2000, avec quelques épisodes de vent violent. Une augmentation de la fréquence des événements de vents extrêmes est attendue d’ici 2030.",
      "high": "Le risque de vents violents est élevé depuis 2000, et une augmentation significative des événements de vents extrêmes est attendue d'ici 2030.",
      "veryHigh": "Le risque de vents violents est très élevé depuis 2000, et une augmentation significative des événements de vents extrêmes est attendue d’ici 2030.",
    },
    "heavyRain": {
      "noRisk": "Le risque de fortes pluies est très faible depuis 2000 et aucun changement significatif dans la fréquence des épisodes de pluies extrêmes n’est attendu d’ici 2030.",
      "low": "Le risque de fortes pluies est faible depuis 2000, avec peu ou pas d’événements dommageables. Une légère augmentation de la fréquence des épisodes de précipitations extrêmes est attendue d’ici 2030.",
      "moderate": "Le risque de fortes pluies est modéré depuis 2000, avec quelques épisodes de vents violents. Une augmentation de la fréquence des épisodes de précipitations extrêmes est attendue d’ici 2030.",
      "high": "Le risque de fortes pluies est élevé depuis 2000, et une augmentation significative des épisodes de pluies extrêmes est attendue d’ici 2030.",
      "veryHigh": "Le risque de fortes pluies est très élevé depuis 2000, et une augmentation significative des épisodes de pluies extrêmes est attendue d’ici 2030.",
    },
    "coastalErosion": {
      "disabled": "Cette adresse n'est pas dans une zone identifiée à risque d'érosion côtière.",
      "noRisk": "L'érosion côtière observée à ce jour reste très faible. Les prévisions restent d'une faible érosion pour cette zone d'ici 2050.",
      "low": "Le risque d'érosion côtière est faible pour cette zone. L'augmentation du niveau de la mer devrait n'affecter légèrement cette zone que jusqu'en 2050.",
      "moderate": "Le risque d'érosion côtière est modéré pour cette zone. L'élévation du niveau de la mer devrait affecter cette zone jusqu'en 2050.",
      "high": "Le risque d'érosion côtière est élevé pour cette zone. L'élévation du niveau de la mer devrait affecter fortement cette zone jusqu'en 2050.",
      "veryHigh": "Le risque d'érosion côtière est très élevé pour cette zone. L'augmentation du niveau de la mer devrait affecter considérablement cette zone jusqu'en 2050."
    },
    "submersion": {
        "disabled":"Cette adresse n'est pas dans une zone identifiée à risque de submersion.",
        "noRisk": "Le risque de submersion est très faible pour cette zone.",
        "low": "Le risque de submersion est faible pour cette zone.",
        "moderate": "Le risque de submersion est modéré pour cette zone.",
        "high": "Le risque de submersion est élevé pour cette zone.",
        "veryHigh": "Le risque de submersion est très élevé pour cette zone."
    }
  },

  "map": {
    "underConstruction": "Carte en cours de construction",
    "limitedZoomLevelMessage": "Niveau max/min de zoom limité pour des raisons techniques."
  },

  "warning": {
    "catNat": "Une ou plusieurs CATNAT par le passé",
    "mandates": "Un ou plusieurs décrets annoncés"
  },
  "name": "Libellé",
  "riskType": "Type de risque",
  "eventRiskType": {
    "drought": "Sécheresse",
    "flood": "Crues",
    "pluvial_flood": "Inondations",
    "fire": "Incendies",
    "hail_storm": "Grêle",
    "wind": "Vents violents",
    "heavy_rain": "Fortes pluies",
    "coastal_erosion": "Érosion côtière",
    "submersion": "Submersion",
    "landslide": "Mouvement de terrain"
  },
  "addEvent": "Ajouter un évènement",
  "or": "ou",
  "contentType": "Type de contenu",
  "inseeCodeInputPlaceholder": "Saisir un code insee",
  "decree": "Décret",
  "upload": "Téléversement",
  "eventEditor": {
    "uploadEventFileLabel": "Téléverser un fichier d'évènements",
    "selectEventFileLabel": "Choisissez un fichier d'évènements",
    "upload": {
      "linesWithError": "Lignes contenant des erreurs",
      "reason": "Erreur",
      "already_exist": "Existe déjà",
    },
    "eventFromDatabase": "Évènements en base de données"
  },
  "continue": "continuer",
  "back": "retour",
  "error(s)": "erreur(s)",
  "addressBulk": {
    "uploadAddressFileLabel": "Téléversement fichier d'adresses",
    "selectAddressFileLabel": "Choisir le fichier d'adresses",
    "previewLabel": "Aperçu",
    "fieldSeparatorLabel": "Séparateur de champs",
    "lineSeparatorLabel": "Séparateur de lignes",
    "hasHeaderLabel": "Contient une ligne d'en-tête",
    "availableColumnsLabel": "Colonnes disponibles",
    "selectedColumnsLabel": "Colonnes sélectionnées",
    "generateReportLabel": "Générer le rapport",
    "comma": "virgule",
    "semicolon": "point-virgule",
    "windows": "Windows",
    "mac": "Mac",
    "linux": "Linux",
    "currentUsageLabel": "utilisation",
    "fileSelectionDescription": "Sélectionnez le fichier CSV contenant les adresses",
    "fileNameLabel": "nom du fichier",
    "fileSize": "taille du fichier",
    "reportGenerationLabel": "Options de génération du fichier",
    "selectAll": "Sélectionner tout",
    "deselectAll": "Désélectionner tout",
    "inputFileValidation": {
      "isEmpty": "Le fichier d'adresse est vide",
      "mustContainOnlyOneColumn": "Le fichier ne doit contenir qu'une seule colonne",
      "rowCountIsGreaterThanUsage": "Le nombre d'adresse est supérieur au nombre de requêtes restantes"
    }
  }
};